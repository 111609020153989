import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Img from "gatsby-image"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import Seo from "../components/global/seo/seo.jsx"
const PaslanmazSintine = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(
        relativePath: { eq: "filtre-galeri/seperatorfiltresi8.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Paslanmaz Sintine Filtresi - Paslanmaz Filtre Erez Kaynak " 
        description="Firmamız proje çizimine yada numuneye göre müşterilerimizin istekleri doğrultusunda paslanmaz sintine filtresi imalatı yapmaktadır. Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak istediğiniz ölçü, numune yada projeye göre paslanmaz sintine filtresi imalatını gerçekleştirmekteyiz."
        keywords="Paslanmaz Sintine Filtresi,paslanmaz sintine eleği,paslanmaz sintine filtresi fiyatları,sintine filtresi,sintine filtre imalatı,sintine filtre,paslanmaz sintine filtresi,paslanmaz sintine filtre fiyatı,paslanmaz sintine filtre imalatı,sintine elek,sintine filtre fiyatı,sintine fiyat"
        url="paslanmaz-sintine-filtresi"
        imgurl={data.resim1.childImageSharp.thumb.src}
      />

      <Box my={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box p={3}>
                <Typography component="h1" variant="h4">
                  <Box textAlign="center" fontWeight={500} mt={3}>
                    Paslanmaz Sintine Filtresi
                  </Box>
                </Typography>
                <Lead>
                  Firmamız proje çizimine yada numuneye göre müşterilerimizin
                  istekleri doğrultusunda{" "}
                  <span>paslanmaz sintine filtresi imalatı</span> yapmaktadır.
                </Lead>
                <Lead>
                  Göz aralıkları mikronlar ile ölçülebilen mesh telleri
                  kullanılarak istediğiniz ölçü, numune ya da projeye göre{" "}
                  <span>paslanmaz sintine filtresi imalatını</span>{" "}
                  gerçekleştirmekteyiz.
                </Lead>
                <Lead>
                  Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
                </Lead>
                <Box textAlign="center" py={3}>
                  <Link to="/iletisim" style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="secondary">
                      İLETİŞİM BİLGİLERİ
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <Img
                  className="img-fluid MuiPaper-elevation5 MuiPaper-rounded"
                  fluid={data.resim1.childImageSharp.thumb}
                  loading="eager"
                  fadeIn={false}
                  alt="Paslanmaz Sintine Filtresi"
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box my={3}>
        <FiltreGaleri />
      </Box>

      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani
              title="Paslanmaz Sintine Filtresi Kullanım Alanları"
              align="center"
            />
          </Box>
        </Paper>
      </Box>
    </Layout>
  )
}
export default PaslanmazSintine
